<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('images.images') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <MediaGridCMS :media="images" />
          </b-overlay>
          <b-row v-if="loadMore">
            <b-col>
              <p class="text-center">
                <b-button variant="primary" @click="loadMoreData" class="my-4" size="lg">
                {{ $t('general.load-more') }} ({{ (page + 1) + "/" + totalPages }})
                </b-button>
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import MediaGridCMS from '@/components/admin/_utils_/MediaGridCMS.vue'

export default {
  name: "AllImages",
  components: { NavBar, MediaGridCMS },
  data: function() {
    return {
      images: [],
      showOverlay: true,
      loadMore: false,
      page: 0,
      totalPages: 0,
      user: this.$root.user
    }
  },
  created() {
    this.fetchData();
  },
  computed : {
    _lang: function() {
      return this.$root.lang;
    }
  },
  methods: {
    async loadMoreData() {
      this.page++;
      await this.$http.get("/cms/api/media/all/image/" + this.$root.lang.IANA + "?page=" + this.page, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
        this.images = this.images.concat(r.data.content);
        this.loadMore = r.data.hasNext;
        this.showOverlay = false;
      }).catch(e => {
        this.handleError(e);
      })
    },
    async fetchData() {
      this.showOverlay = true;
      await this.$http.get("/cms/api/media/all/image/" + this.$root.lang.IANA, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
        this.images = r.data.content;
        this.loadMore = r.data.hasNext;
        this.totalPages = r.data.totalPages;
      }).catch(e => {
        this.handleError(e);
      });
      if (this.page > 0) {
        // console.log(this.page);
        const openedPages = this.page;
        this.loadMore = false;
        this.page = -1;
        this.images = [];
        for (let i = 0; i <= openedPages; i++) {
          // console.log("Učitavam stranu: " + i);
          await this.loadMoreData();
        }
      }
      this.showOverlay = false;
    }
  },
  watch: {
    async _lang(newLang, oldLang) {
      this.fetchData();
    }
  }  
}
</script>
<style></style>
