<template>
  <component :is="roleMenu"></component>
</template>

<script>
export default {
    name: 'NavBar',
    components: { },
    data() {
      return {
        user : this.$root.user
      }
    },
    computed: {
      roleMenu() {
        return () => import("@/components/common/navbar/" + this.$root.user.role.name + ".vue");
      }
    }
}
</script>

<style></style>
